import {Dropdown} from 'bootstrap';
import { constants } from '../core/constant';
import UTILS from '../core/utils';
import Analytics from '../core/analytics';

export default class MainNav {

  constructor() {

    this.selectors = {
      topNav : '.top-nav',      /* Main nav with logo, Search nd Sub nav dropdowns */
      topNavSecondary : '.top-nav-secondary',   /* Upper nav with login and contact links */
      allDropdowns: '.top-nav .dropdown, .top-nav-secondary .dropdown',
      externalToggle: '.top-nav-secondary .dropdown-external-toggle',
      investorTypeLink_desktop : '[data-target=investorType--link]',
      investorTypeLink_mobile : '#investorType--link',
      dropdownMenu: '.dropdown-menu',
      search: '.top-nav .search #txt-search',
      isJapanese: '#jpdomain'
    }
    this.classes = {
      hide: 'hide',
      show: 'show',
      overlay: 'overlay',
      navOpen: 'nav-open',
    }
    this.events = {
      countryChanged : 'country-changed',
      investorChanged : 'investor-changed'

    }
    this.allDropdowns = document.querySelector(this.selectors.allDropdowns);
    this.topNavSecondary = document.querySelector(this.selectors.topNavSecondary);
    this.onDropdownShow = this.onDropdownShow.bind(this);
    this.onDropdownHide = this.onDropdownHide.bind(this);
    this.onExternalToggle = this.onExternalToggle.bind(this);
    this.updateInvestor = this.updateInvestor.bind(this);
    this.updateCountry = this.updateCountry.bind(this);
    this.search =  document.querySelector(this.selectors.search);
    this.isJapanese = document.querySelector(this.selectors.isJapanese);
  }

  trackNavInteraction(e) {
    let linkText = e.target.previousElementSibling ? e.target.previousElementSibling : e.target;
    let eventObj = Analytics.getAnalyticsObjectFor('generic');
    eventObj.action_type = e.type.indexOf('show') > -1 ? 'Navigation expanded' : 'Navigation closed';
    eventObj.link_text = linkText.innerText.trim();
    eventObj.module_name = "Navigation";
    Analytics.pushToAnalytics(eventObj);
  }

  onDropdownShow(e) {
    this.trackNavInteraction(e);
    document.body.classList.add(this.classes.overlay, this.classes.navOpen);
  }

  onDropdownHide(e) {
    this.trackNavInteraction(e);
    document.body.classList.remove(this.classes.overlay, this.classes.navOpen);

    // Toggle external handler if exist
    var externalToggle  = document.querySelectorAll(".dropdown-external-toggle[data-target='"+ e.target.getAttribute('id') +"--link']");

    if(externalToggle.length){
      externalToggle[0].classList.remove("show");
    }
  }

  inputSearchValidation() {
    var field = document.getElementById("txt-search");
    field.addEventListener("keypress", function (event) {
        var key = event.keyCode;
        if (key === 32 && event.target.value == "") {
            event.preventDefault();
        }
    });


    document.addEventListener(
        "invalid",
        (function () {
            return function (e) {
                e.preventDefault();
                document.getElementById("txt-search").focus();
            };
        })(),
        true
    );
  }


  onExternalToggle(e) {
    e.preventDefault();
    e.stopPropagation();

    const newTarget = e.currentTarget.getAttribute('data-target');
    const myDropdown = document.getElementById(newTarget);
    const objDropdown = new Dropdown(myDropdown);
    //this.classList.toggle("show"); // TODO: correct this by linking it to the navopen close
    objDropdown.toggle();
  }

  bindEvents() {
    document.querySelectorAll(this.selectors.externalToggle).forEach((item) => {
            item.addEventListener("click", this.onExternalToggle);
    });

    document.querySelectorAll(this.selectors.allDropdowns).forEach((item) => {
      item.addEventListener("show.bs.dropdown", this.onDropdownShow);
      item.addEventListener("hide.bs.dropdown", this.onDropdownHide);

      // prevent sub-nav click to close dropdown
      var subNav = item.querySelector(this.selectors.dropdownMenu);
      if(subNav) {
        subNav.addEventListener('click', (e) => {
          e.stopPropagation();
        });
      }
    });
  }

  updateCountry(countryObj) {
    if(countryObj && countryObj.country && countryObj.country.length) {
      var flag_desktop = document.querySelector(this.selectors.investorTypeLink_desktop)?.querySelector('.flag-selected');
      var flag_mobile = document.querySelector(this.selectors.investorTypeLink_mobile)?.querySelector('.flag-selected');

      if(flag_desktop){
        flag_desktop.src = constants.flagPath + countryObj.countryCode + constants.flagExt;
        flag_desktop.alt = constants.flagPath + countryObj.countryCode + constants.flagExt;
      }

      if(flag_mobile){
        flag_mobile.src = constants.flagPath + countryObj.countryCode + constants.flagExt;
        flag_mobile.alt = constants.flagPath + countryObj.countryCode + constants.flagExt
      }
    }

  }

  updateInvestor (countryObj) {
    var span_desktop = document.querySelector(this.selectors.investorTypeLink_desktop)?.querySelector('span');
    var span_mobile = document.querySelector(this.selectors.investorTypeLink_mobile)?.querySelector('span');

    if(countryObj.countryCode && countryObj.countryCode != constants.globalCountryCode) {
      if(countryObj.investType && countryObj.investType.length && span_desktop) {
        span_desktop.innerText = countryObj.investType;

		if(span_mobile) span_mobile.innerText = countryObj.investType;
      } else if(span_desktop) {
        span_desktop.classList.add('d-none')
		    if(span_mobile) span_mobile.classList.add('d-none')
      }
    }
  }

  observeSticky() {
    const stickyElm = document.getElementById('top-nav');
    // const stickyClass = this.classes.isSticky;

    try {
      const observer = new IntersectionObserver(function(e) {
        if(e && e[0] && e[0].target) {
          if(e[0].isIntersecting) {
            // e[0].target.classList.remove(stickyClass)
          } else {
            // e[0].target.classList.add(stickyClass)
          }
        }
      },{
        rootMargin: '-1px 0px 0px 0px',
        threshold: [1],
      });

      observer.observe(stickyElm)
    }
    catch(err) {
      // console.error(err)
      // console.error("Setting top-nav background as Browser do not support IntersectionObserver");
      stickyElm.classList.add(stickyClass);
    }
  }

  init() {
    // return if top Nav don't exist
    if(!document.querySelector(this.selectors.topNav)) {
      return
    }
    this.bindEvents();
    this.observeSticky();
    this.inputSearchValidation();

    var countryObj = UTILS.getCookieObject();

    if(countryObj) {
      this.updateCountry(countryObj);
      this.updateInvestor(countryObj);
    }
  }
}

window.addEventListener('DOMContentLoaded', function (event) {
  const mainNav = new MainNav();
    mainNav.init();
});

//top navigation sticky animation code start

  let mainNav = document.querySelector('.main-nav').offsetHeight;
  let scroll_position = 0, scroll_direction;
  let body = document.querySelector('body');
  body.classList.add('stickyNav');

  const addStickyNav = function(scroll_direction){
    if (scroll_direction === 'down') {
      document.querySelector('.menu-container').classList.add('sticky-to-top', 'top-nav-active');
    } else if (scroll_direction === 'up') {
      document.querySelector('.menu-container').classList.remove('sticky-to-top');
    }
  }

  window.addEventListener('scroll', (e) => {
        let windowWidth = window.innerWidth;

        if(windowWidth > 990){
      scroll_direction = (document.body.getBoundingClientRect()).top > scroll_position ? 'up' : 'down';
            scroll_position = (document.body.getBoundingClientRect()).top;
            addStickyNav(scroll_direction);
      if(window.innerHeight + window.scrollY >= document.body.scrollHeight){
        document.querySelector('.menu-container').classList.add("sticky-to-top");
      }
        }else{
            document.querySelector('.menu-container').classList.add("sticky-to-top");
      }

        if(window.pageYOffset < mainNav){
            document.querySelector('.menu-container').classList.remove('sticky-to-top', 'top-nav-active');
        }
    });

    window.addEventListener('resize', function(){
        let windowWidth = window.innerWidth;
        if(windowWidth < 990){
            document.querySelector('.menu-container').classList.add("sticky-to-top");
            document.querySelector('.menu-container').classList.remove('sticky-to-top', 'top-nav-active');
      body.classList.remove('stickyNav');
        }else if(windowWidth > 990){
      body.classList.add('stickyNav');
      let scroll_position_m = 0;
      let scroll_direction_m;

            window.addEventListener('scroll', (e) => {
        scroll_direction_m = (document.body.getBoundingClientRect()).top > scroll_position_m ? 'up' : 'down';
        scroll_position_m = (document.body.getBoundingClientRect()).top;
        addStickyNav(scroll_direction_m);
        if(window.innerHeight + window.scrollY >= document.body.scrollHeight){
          document.querySelector('.menu-container').classList.add("sticky-to-top");
        }
                if(window.pageYOffset < mainNav){
                    document.querySelector('.menu-container').classList.remove('sticky-to-top', 'top-nav-active');
                }
            });
        }
    })

  //Following code for toggle menu dropdown open
  let mainTopNavArr = Array.from(document.querySelectorAll('.main-nav .list-inline a'));

  mainTopNavArr.forEach(el =>{
    el.addEventListener('click', (e)=>{;
      const eName = e.target.closest('a').hash.substring(1);
      const eLink = document.querySelector(`#${eName}--link`);
      
      if(!eLink?.className?.includes('show')){
        //find all .show class
        let shows = document.querySelectorAll('#top-nav .show');
        shows.forEach((e)=>{
          if(e.id !== eLink?.id.slice(0, -6)){
            e.classList.remove('show');
          }
        })
      }
    })
  });

  //following script is used for avaiding "nav-opn" class toggle
  let menuSpanArr = Array.from(document.querySelectorAll('.menu-container a'));
  const isJapan = document.querySelector('#jpdomain');
  menuSpanArr.forEach(el =>{
    el.addEventListener('click', ()=>{
      setTimeout(()=>{
        let menuOpen = document.querySelectorAll('.show');       
        let dropdownToggle = el.getAttribute('aria-expanded');
        let subNav = document.querySelectorAll('.navbar .sub-nav.show a');
        if((dropdownToggle === 'true' && menuOpen.length !== 0) || (subNav.length !== 0)){
          document.querySelector('body').classList.add('overlay', 'nav-open');
        }
        else {
          document.querySelector('.stickyNav').classList.remove('overlay', 'nav-open');
        }
      },30);
    });
  });
  
  //header navigation accessibility code start

  (function() {
    if( window.innerWidth >= 1024 ) {
      // selecting nav-items
      const mainNavList = document.querySelectorAll('#top-nav-items .navbar-nav>li.nav-item');
      const mainNavListArr = Array.from(mainNavList);
      const newList = mainNavListArr.filter((el)=>{
        if(!el.classList.contains('p-lg-0')){
          return el;
        }
      })

      document.addEventListener('focusin', function(el) {
        document.querySelector('.search.d-flex')?.addEventListener('keydown', function(e){
          if (e.code === "Tab") {
            newList[newList.length - 1].setAttribute('tabindex', '0');
            if (e.shiftKey) {//shift+tab pressed
              newList[newList.length - 1].children[0].focus();
            } else {//only tab pressed
            }
            newList[newList.length - 1].addEventListener('blur', (e) => {
              e.target.removeAttribute('tabindex');
            })
          }
        })

        let activeEl = document.activeElement.id;
        if(activeEl === 'bookmarks' && document.querySelectorAll('#bookmarks a').length === 0){
          document.activeElement?.addEventListener('keydown', function(e){
            if (e.code === "Tab") {
              if (e.shiftKey) {//shift+tab pressed
              } else {//only tab pressed
                document.querySelector('.bookmarkLink').focus();
              }
            }
          })
        }

        const onFocusMoveFocusToTarget = (matchingID, dataTarget) => {
          if(activeEl === `${matchingID}`){
            document.querySelector(`[data-target="${dataTarget}"]`).focus();
          }
        }

        onFocusMoveFocusToTarget("corporate--link", "corporate--link");
        onFocusMoveFocusToTarget("bookmarks--link", "bookmarks--link");

        // add click event to top nav links
        const moveFocusOnClick = (matchingSting, target) =>{
          if(document.activeElement.dataset.target === `${matchingSting}`){
            document.activeElement.addEventListener('click', ()=>{
              document.querySelector(`${target}`).focus();
            })
          }
        }

        moveFocusOnClick("corporate--link", '#corporate');
        moveFocusOnClick("bookmarks--link", '#corporate');
        
        if(document.activeElement.dataset.target === "bookmarks--link"){
          document.querySelector('[data-target="bookmarks--link"]').addEventListener('click', () =>{
            document.querySelector('#bookmarks').setAttribute("tabindex", "0");
            document.querySelector('#bookmarks').focus();
          })
        }
      }, true);

      function selectLastItemFromArray(arrayName, target){
        arrayName[arrayName.length - 1]?.addEventListener('keydown', function(e){
          if (e.code === "Tab") {
            if (e.shiftKey) {//shift+tab pressed
              arrayName[arrayName.length - 2]?.focus();
            } else {//only tab pressed
              document.querySelector(target).focus();
            }
          }
        })
      }

      selectLastItemFromArray(newList, '.search.d-flex');

      window.addEventListener('load', () =>{
        const anchoreInBookmarkDiv = document.querySelectorAll('#bookmarks a');
        const allAnchoreInBookmarkDiv = Array.from(anchoreInBookmarkDiv);
        const bookmarkLink = document.querySelector('[data-target="bookmarks--link"]');

        if(document.querySelector('.fas.fa-lock')){
          document.querySelector('.fas.fa-lock').closest('.nav-link').setAttribute('tabindex', '0');
        }
        
        if (bookmarkLink){
          const loginLink = document.querySelector('[data-target="bookmarks--link"]').classList.add('bookmarkLink')
          selectLastItemFromArray(allAnchoreInBookmarkDiv, '.bookmarkLink');
        }
      })
    }
  })();
  //header navigation accessibility code end
